import React from 'react';
import {BsLinkedin, BsGithub} from 'react-icons/bs';


const SocialMedia = () => (
    <div className="app__social">

        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/brandon-worthy-3b093712a/">
            <div>
                <BsLinkedin />
            </div>
        </a>

        <a target="_blank" rel="noopener noreferrer" href="https://github.com/kaliadmen">
            <div>
                <BsGithub />
            </div>
        </a>

    </div>
);

export default SocialMedia;